<template>
  <div class="menu-facture-frais">
    <!-- <div class="list-tabs">
      <v-tabs
        bg-color="#704ad1"
        :hide-slider="false"
        slider-color="#704ad1"
        v-model="model"
      >
        <v-tab :href="`#tab-${0}`">
          <router-link
            to="/frais-th/simulation-facture-frais"
            class="font-tabs-facture"
          >
            Simulation facture
          </router-link>
        </v-tab>
        <v-tab :href="`#tab-${1}`">
          <router-link
            to="/frais-th/vision-globale-frais"
            class="font-tabs-facture"
          >
            Gestion global des frais
          </router-link>
        </v-tab>
      </v-tabs>
    </div> -->
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      model: 0
    };
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'getShowNotifExportFile',
      'computedRouterLinkFacture'
    ])
  },
  watch: {
    $route(e) {
      if (this.computedRouterLinkFacture) {
        this.model = e.meta.tab;
      }
    }
  },
  mounted() {
    if (this.computedRouterLinkFacture) {
      this.model = this.$route.meta.tab;
    }
  }
};
</script>
